<template>
  <div>

    <div id="header" class="shadow-sm">
      <div class="container">
        <nav class="navbar m-0 p-0">
          <a href="#" class="navbar-brand">
            <img
              src="../assets/ontrack-logo.png"
              class="img-fluid"
              width="120px"
              alt="logo"
            />
          </a>
          <ul class="navbar-nav ml-auto">
            <!-- <li class="nav-items">
              <a href="#" class="nav-link">Calculator</a>
            </li> -->
            <!-- <li class="nav-items">
              <a href="#" class="nav-link">Calculator</a>
            </li> -->
            <p class="my-auto m-0 p-0" @click="goToEmiCalculator">EMI Calculator</p>
          </ul>
        </nav>
      </div>
    </div>
    <div class="main-content py-5">
    <div class="container col-12 col-lg-10 mx-auto">
      <div class="row">
        <div class="col-md-6 px-3 my-auto">
          <p class="text-primary m-0 p-0">
            <em>India’s fastest and...</em>
          </p>
          <h1 class="fw-bold">
            Super Affordable Bike Loans 
          </h1>
          <p class="sub-title mb-4 ">
            Low Interest Rate | Instant Approval | Flexible Tenure
          </p>
          <div class="col-12 col-md-8 col-lg-6 m-0 p-0">
            <b-button @click="openForm" block class="px-5" variant="primary" size="lg">
            APPLY NOW
          </b-button>
          <p class="thunder-icon pt-2 para-2 text-center">
            <i class="fas fa-bolt text-warning"></i>
            Instant Approval
          </p>
          </div>
        </div>
        <div class="col-md-6" v-if="screen === 'large'">
          <div class="cover-img mx-3">
            <img
              src="../assets/banner-m.svg"
              class="img-fluid"
              width="350px"
              alt="banner-logo"
            />
          </div>
        </div>
      </div>
    </div>
    
    <div class="container col-md-10 mx-auto m-md-0 p-md-0">
      <b-row class="p-2 p-md-0">
        <!-- <div class="scrolling-wrapper row flex-row flex-nowrap mx-auto no-gutter"> -->
          <b-col class="col-6 col-md-3 p-2 py-md-0 align-items-stretch d-flex ">
            <div class="card-repayment p-2 py-3 p-md-4">
              <p class="">
                  Interest Rate as low as 7.9%
                </p>
            </div>
          </b-col>
          <b-col class="col-6 col-md-3 p-2 py-md-0 align-items-stretch d-flex">
            <div class="card-online-support p-2 py-3 p-md-4">
              <p class="">
                  Upto 42 months of repayment tenure
                </p>
            </div>
          </b-col>
          <b-col class="col-6 col-md-3 p-2 py-md-0 align-items-stretch d-flex">
            <div class="card-online-support p-2 py-3 p-md-4">
              <p class="">
                  Processing fees as low as 1%
                </p>
            </div>
          </b-col>
          <b-col class="col-6 col-md-3 p-2 py-md-0 align-items-stretch d-flex">
            <div class="card-repayment p-2 py-3 p-md-4">
              <p class="">
                  Funding upto 95% of the vehicle ORP
                </p>
            </div>
          </b-col>
        <!-- </div> -->
      </b-row>
    </div>

    <div class="bg-offer" style="margin-top:50px">
      <!-- Emi calculator -->
      <div class="row">
        <div class="col-12 col-lg-8 py-5 p-md-5 mx-auto">
          <div class="p-2">
            <div class="p-2">
              <h3 class="text-center mb-5">Two Wheeler Loan Calculator</h3>
              <!-- col-divide for EMI -->
              <div class="row">
                <div class="col-9 my-auto">
                  <form>
                    <div class="form-group">
                      <label>Total Loan Amount</label>
                      <input :step="1000" :min="25000" :max="400000" v-model="totalLoanAmount" type="range" class="form-control-range">
                    </div>
                  </form>
                </div>
                <div class="col-3 text-right my-auto">
                  <h6>₹ {{totalLoanAmount}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-9 my-auto">
                  <form>
                    <div class="form-group">
                      <label>Loan Tenure</label>
                      <input v-model="totalYears" max="3" min="1" step="1" type="range" class="form-control-range">
                    </div>
                  </form>
                </div>
                <div class="col-3 text-right my-auto">
                  <h6> {{totalYears}} Yrs</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-9 my-auto">
                  <form>
                    <div class="form-group">
                      <label>Interest Rate</label>
                      <input v-model="totalInterestRate" max="15" min="7.9" step="0.1" type="range" class="form-control-range">
                    </div>
                  </form>
                </div>
                <div class="col-3 text-right my-auto">
                  <h6>{{totalInterestRate}}%</h6>
                </div>
              </div>
              <p>You will Pay an EMI of Only <span class="inr-sign"></span> 
                <span class="bold heading-3">{{totalEmi}}</span>
                /-monthly
              </p>
              <div class="col-12 col-md-8 col-lg-6 mt-5">
                <b-button block class="px-5" @click="openForm" size="lg" variant="primary">APPLY NOW</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-5 col-md-10 mx-auto">
      <div class="row no-gutter mt-md-5">
        <!-- more col for right hand details -->
        <div class="col-md-7">
          <div class="card border-0">
            <div class="card-body p-md-5" style="background:#F8F9FA">
              <h2 class="card-title py-2 fw-bold">
                Why Two-Wheeler Loan?
              </h2>
              <p class="wheeler-text-details">
                Two-Wheeler Loans or Bike Loans are a smart way of owning a Bajaj Motorcycle in today’s time.
              </p>
              <p>
                Owning a bike has two frequent costs associated with it, one of the fuel and other of maintenance, and both these over the bike’s lifetime can cost as much as the bike at the time of purchase. To address this problem, Bajaj Auto Finance is offering 2-Wheeler loans for as long as 42 months.
              </p>
              <p>
                You’ll only pay a small portion of the vehicle’s cost over a time that you find comfortable.
              </p>
              <div class="col-12 col-md-8 col-lg-6">
                <b-button block class="px-5" @click="openForm" size="lg" variant="primary">APPLY NOW</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5">
         <div class="card border-dashed">
            <div class="card-body">
              <h3 class="">Features and Benifits</h3>
              <!-- dividing col again into 12 -->
              <div class="row">
                <div class="col-2">
                  <p class="py-2 fw-bold">
                      <i class="fab fa-wpforms icon-right-side text-primary"></i>
                  </p>
                </div>
                <div class="col-10">
                  <p class="card-sub-title py-2 fw-bold m-0 p-0">
                    Simple Application
                  </p>
                  <p class="card-text-details small">
                    Ontrack has partnered with Bajaj Auto Finance to assist you with your bike loan. All you have to do now is fill in the simple form by clicking on “Apply Now”. Someone from our team or Bajaj Auto Finance will reachout to you shortly and assist with the application.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <p class="py-2 fw-bold">
                    <i class="fab fa-creative-commons-zero icon-right-side text-primary"></i>
                      <!-- <i class="fas fa-hand-point-right icon-right-side text-primary"></i> -->
                  </p>
                </div>
                <div class="col-10">
                  <p class="card-sub-title py-2 fw-bold m-0 p-0">
                    Zero Hidden Charges
                  </p>
                  <p class="card-text-details small">
                    We stand by this, our team will communicate all the charges before you make a decision. No last minute surprise. We really want your bike purchase experience with us to be smooth.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <p class="py-2 fw-bold">
                    <i class="fas fa-receipt icon-right-side text-primary"></i>
                  </p>
                </div>
                <div class="col-10">
                  <p class="card-sub-title py-2 fw-bold m-0 p-0">
                    Minimum Downpayment
                  </p>
                  <p class="card-text-details small">
                    Seriously, we really don’t want to share too much with us. Infact you can get a bike loan from us without a bank account or even income proof.
                  </p>
                  <p class="card-text-details small">
                    If you have never borrowed before, we’ll be happy to be your first lender.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <p class="py-2 fw-bold">
                    <i class="fas fa-chart-pie icon-right-side text-primary"></i>
                  </p>
                </div>
                <div class="col-10">
                  <p class="card-sub-title py-2 fw-bold m-0 p-0">
                    Lowest Processing Fees
                  </p>
                  <p class="card-text-details small">
                    Now that’s one of the biggest advantage when you apply via Ontrack. Usually you pay about 3-4% of your loan amount as processing fees via any dealership.
                  </p>
                  <p class="card-text-details small">
                    With us, you’ll only pay 1%. What’s more? If you apply for a Bajaj Chetak EV - we’ll make your processing fees to 0.
                  </p>
                </div>
              </div>
            </div>
         </div> 
        </div>
      </div>
    </div>

    <div class="container mt-5 col-md-10 mx-auto">
      <div class="row no-gutter">
        <div class="col-md-8">
          <div class="card border-0">
            <div class="card-body">
              <h2 class="card-title py-2 fw-bold">Who is Eligible for the loan?</h2>
              <p class="card-text-details m-0 p-0">
                For the approval of your bike loan from Bajaj Auto Finance, you need to fulfil the stipulated eligibility conditions. Here’s a look at the eligibility conditions:
              </p>
              <p class="mx-4">
                <ul class="medium">
                  <li>Nationality: For making a loan application, you need to be an Indian citizen.</li>
                </ul>
              </p>
              <p class="mx-4">
                <ul class="card-text-details medium">
                  <li>Age: The minimum age for receiving an Bajaj Auto Finance Bike Loan is 18 years, a person can apply for a bike loan till the age of 65 years.</li>
                </ul>
              </p>
              <p class="mx-4">
                <ul class="card-text-details medium">
                  <li>Occupation: Salaried, Self-Employed, Agri Employment and Dependants </li>
                </ul>
              </p>
              <p class="mx-4">
                <ul class="card-text-details medium">
                  <li>Bank Account: Not Mandatory</li>
                </ul>
              </p>
              <p class="mx-4">
                <ul class="card-text-details medium">
                  <li>Income Proof - With or without income proof, even new to credit can be processed</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center pb-5 my-auto">
          <div class="loan-img">
            <img src="../assets/banner-s.svg" class="img-fluid" width="250px" alt="">
          </div>
        </div>
      </div>
    </div>
    
    
    <!-- Vertical navs sections -->
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-10 m-auto">
          <div class="card border-0">
            <div class="card-body">
              <h2 class="text-center mb-5">How it Works ?</h2>
              <!-- col-divide for navs-vertical -->
              <b-row class="my-5">
                <b-col cols="2" class="my-auto text-center">
                  <div>
                    <h4 class="text-primary">
                      1.
                    </h4>
                  </div>
                </b-col>
                <b-col class="my-auto">
                  <div>
                    <h3>
                      Fill Your Basic Details
                    </h3>
                    <p>Someone from our team or Bajaj Auto Finance will reach out to you immediately.</p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="my-5">
                <b-col cols="2" class="my-auto text-center">
                  <div>
                    <h4 class="text-primary">
                      2.
                    </h4>
                  </div>
                </b-col>
                <b-col class="my-auto">
                  <div>
                    <h3>
                      Documentation
                    </h3>
                    <p>We’ll help you with documentation and understand your eligibility, once eligible, we’ll underwrite your loan and come back with an offer.</p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="my-5">
                <b-col cols="2" class="my-auto text-center">
                  <div>
                    <h4 class="text-primary">
                      3.
                    </h4>
                  </div>
                </b-col>
                <b-col class="my-auto">
                  <div>
                    <h3>
                      Purchase Bike
                    </h3>
                    <p>If our loan terms are acceptable by you, our team will assist with the purchase process. You can ride home a brand new Bajaj Motorcycle.</p>
                  </div>
                  <div class="col-12 col-md-8 col-lg-6 mt-5 m-0 p-0">
                <b-button block class="px-5" @click="openForm" size="lg" variant="primary">APPLY NOW</b-button>
              </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer area -->

    <b-container class="p-3" style="background:#fafafa">
      <b-row>
        <b-col class="col-12 my-auto col-md-3">
          <p>Since you’ve made it all the way here, <strong>do checkout our two-wheeler Super App</strong></p>
          <p>Rent | Finance | RTO | Rewards | Services</p>
        </b-col>
        <b-col class="col-12 my-auto text-center col-md-4">
          <img width="200px" class="mx-auto" src="../assets/phone.png" alt="">
        </b-col>
        <b-col class="col-12 my-auto col-md-5 m-0">
          <img src="../assets/ontrack-logo.png" width="100px" alt="">
          <div class="d-flex mt-4">
            <img class="pr-2" @click="redirect('https://apps.apple.com/in/app/ontrack-monthly-bike-rental/id1433908606')" src="../assets/appstore.svg" width="120px" alt="">
            <img @click="redirect('https://play.google.com/store/apps/details?id=com.ontrack.ontrack')" src="../assets/play.svg" width="120px" alt="">
          </div> 
          <hr>
          <b-row class="mb-4 text-center" no-gutter style="font-size:12px">
            <b-col @click="redirect('https://www.bajajautofinance.com/')" cols="5"><p class="text-primary pr-2 m-0 p-0 ">Visit Bajaj Auto Finance</p></b-col>
            <b-col @click="redirect('https://on-track.in')" cols="4"><p class="text-primary pr-2 m-0 p-0 ">Visit Website</p></b-col>
            <b-col @click="redirect('https://on-track.in/contact/')" cols="3"><p class="text-primary pr-2 m-0 p-0 ">Contact Us</p></b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <!-- FAQ Accordion -->
    <!-- <div class="container col-md-10 mx-auto">
      <section id="faqs">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <h2 class="text-center">Frequently Asked Questions</h2>
            <div class="accordion mt-5" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h2 class="clearfix mb-0">
                    <a class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
                    What is a two-wheeler loan EMI calculator ?
                    <i class="fa fa-angle-down"></i>
                  </a>
                  </h2>
                </div>
                <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                  data-parent="#accordionExample">
                  <div class="card-body">
                    <p class="para-3 text-justify">
                      A bike loan EMI calculator is an application that helps you calculate the factors that come into play for your bike EMI. When you put in details such as your down payment amount, the duration of the loan and the bike loan interest rate, the EMI calculation formula for the two-wheeler loan will calculate the EMI amount you will have to pay every month.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h2 class="clearfix mb-0">
                    <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    How to calculate your EMI using a two-wheeler loan calculator ?
                    <i class="fa fa-angle-down"></i>
                  </a>
                  </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                  data-parent="#accordionExample">
                  <div class="card-body">
                    <p class="para-3 text-justify">
                      A bike loan EMI calculator is an application that helps you calculate the factors that come into play for your bike EMI. When you put in details such as your down payment amount, the duration of the loan and the bike loan interest rate, the EMI calculation formula for the two-wheeler loan will calculate the EMI amount you will have to pay every month.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h2 class="clearfix mb-0">
                    <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                    aria-expanded="true" aria-controls="collapseThree">
                    How to lower your two-wheeler loan interest rate?
                    <i class="fa fa-angle-down"></i>
                  </a>
                  </h2>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                  data-parent="#accordionExample">
                  <div class="card-body">
                    <p class="para-3 text-justify">
                      A bike loan EMI calculator is an application that helps you calculate the factors that come into play for your bike EMI. When you put in details such as your down payment amount, the duration of the loan and the bike loan interest rate, the EMI calculation formula for the two-wheeler loan will calculate the EMI amount you will have to pay every month.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div> -->
  </div>
    
  <Transition name="right-fade">
  <div class="custom-modal" v-if="showForm">
    <div class="col-12 col-md-6 mx-auto m-0 p-2" style="background:#F5F5F5">
      <div class="d-flex" >
      <div class="my-auto">
        <b-button pill @click="showForm = false" variant="light"><i class="far fa-long-arrow-left"></i></b-button>
      </div>
      <div class="my-auto ml-3">
      <h5 class="m-0 p-0 text-muted">Loan Application</h5>
      </div>
    </div>
    <p class="text-muted px-3 m-0 pt-2">
      <small>Just some details to see if you pre-qualify for this loan.</small>
    </p>
    </div>
    
    <div class="p-3">
      <LoanForm :utm-source="utmSource" :campaign="campaign"></LoanForm>
    </div>
  </div>
</Transition>
    
  <b-modal body-class="py-2" ref="openFormModal" scrollable centered hide-footer>
    <template #modal-title>
      <h4 class="m-0 p-0">Loan Application</h4>
      <p class="text-muted m-0 p-0" style="font-size:17px">
        <small>Just some details to see if you pre-qualify for this loan.</small>
      </p>
    </template>
    <div>
      <LoanForm :utm-source="utmSource" :campaign="campaign"></LoanForm>
    </div>
  </b-modal>
  <!-- {{screen}} -->
  <VueBottomSheet :isFullScreen="true" ref="openFormBottomSheet">
    <div class="">
      <LoanForm :utm-source="utmSource" :campaign="campaign"></LoanForm>
    </div>
  </VueBottomSheet>
    <!-- ROUTER OUTLET SHOULD BE GIVEN HERE <router-link></router-link> -->
    <!-- any foter should be included here <app-footer></app-footer> -->
  </div>
</template>

<script>
// import { eventBus } from '@/main';
import LoanForm from './loanForm.vue'
export default {
  components: { LoanForm },
  data() {
    return {
      totalLoanAmount: 200000,
      totalYears: 2,
      totalInterestRate: 8,
      screen: 'small',
      utmSource: 'organic',
      campaign: '',
      showForm: false
    }
  },
  created(){
    if(window.innerWidth > 800) {
      this.screen = 'large'
    }
    // eventBus.$on("close-modal", ()=> {
    //   this.$refs.openFormModal.hide()
    // });
  },
  mounted() {
    this.utmSource = this.$route.query.utm;
    this.campaign = this.$route.query.campaign;
  },
  methods: {
    openForm() {
      // this.$router.push('/bajaj-finance/form')
      // if (this.screen === 'large') {
      //   this.$refs.openFormModal.show()
      // } else {
      //   this.$refs.openFormBottomSheet.open()
      // }
      // this.$refs.openFormModal.show()
      this.showForm = true
    },
    redirect(url){
      window.location.href = url
    },
    goToEmiCalculator(){
      window.scrollTo({
        top: 700,
        left: 100,
        behavior: 'smooth'
      })
    }
  },
  computed: {
    totalEmi() {
      const months = this.totalYears * 12
      const interest =  this.totalLoanAmount * (this.totalInterestRate * 0.01) * this.totalYears 
      // const interest = (this.totalLoanAmount * (this.totalInterestRate * 0.01))/months;
      // const total = ((this.totalLoanAmount/months) + interest);
      const total = (Number(this.totalLoanAmount) + Number(interest)) / months
      return Math.ceil(total)
    }
  }
};
</script>

<style>
.border-dashed {
  border: 1px dashed #c3cfd9 !important;
}
.card-interest {
  background-color: #95C3EC;
}
.card-repayment{
  background-color: #C2CFD9;
  border-radius: 10px;
  min-width: 100%;
}
.card-online-support{
  border-radius: 10px;
  background-color:#95C3EC;
  min-width: 100%;
}
.card-online-process{
  border-radius: 10px;
  background-color: #C2CFD9;
}
.scrolling-wrapper{
	overflow-x: auto;
}
/* card scroll no gap */
.no-gutter > [class*='col-']{
  padding-right: 0;
  padding-left: 0;
}
.icon-right-side {
  font-size: 35px;
}
.bg-offer {
  background: #FFFAE2;
}

/* accordion FAQS css */
.accordion .card-header a{
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color:#222222;
}
.accordion .card-header a:hover{
  text-decoration: none;
  color:#222222;
}
#faqs{
  background-color: #F8F9FA;
  height: 100%;
  padding: 50px 0;
}
.accordion .card{
  border-radius: 0;
} 
.accordion .card .card-header{
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 0;
}
.accordion .card-header .btn{
  width: 100%;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}
.accordion .card-header i{
  font-size: 1rem;
  position: absolute;
  top: 15px;
  right: 1rem;
}
.accordion .card-body{
  background-color: #F8F9FA;
} 
/* accordion Faqs Css End */

.custom-modal{
  min-height: 100vh;
  background: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  width: 100%;
  overflow: scroll;
  height: 100vh;
}

.right-fade-enter-active,
.right-fade-leave-active {
  transition: all 0.3s ease-out;
}

.right-fade-leave-active {
  transition: all 0.3s ease-out;
}

.right-fade-enter,
.right-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
